import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosWrapper } from "../../helper";

export const createThreatProfile = createAsyncThunk(
  "threatProfile/createThreatProfile",
  async ({ level, type }, { rejectWithValue }) => {
    try {
      let url = `tid/onboarding`;
      if (level) {
        url += `/baseline-controls?level=${level}`;
      }
      if (type === "DORA_BASED") {
        url += `/dora`;
      }
      const response = await axiosWrapper.get(url);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createThreatProfileTechBased = createAsyncThunk(
  "threatProfile/createThreatProfileTechBased",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `tid/onboarding/top-techniques`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAllThreatProfiles = createAsyncThunk(
  "threatProfile/getAllThreatProfiles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/threat-profile/all`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getThreatProfileById = createAsyncThunk(
  "threatProfile/getThreatProfileById",
  async ({ threatProfileId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/threat-profile/${threatProfileId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateThreatProfile = createAsyncThunk(
  "threatProfile/updateThreatProfile",
  async ({ threatProfileId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `tid/threat-profile/${threatProfileId}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getExistThreatProfile = createAsyncThunk(
  "threatProfile/getExistThreatProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`tid/threat-profile/exists`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getThreatProfileControls = createAsyncThunk(
  "threatProfile/getThreatProfileControls",
  async ({ threatProfileId, family }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/threat-profile/controls/${threatProfileId}?family=${family}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getThreatProfileMatrix = createAsyncThunk(
  "threatProfile/getThreatProfileMatrix",
  async ({ threatProfileId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `tid/threat-profile/risk-matrix/${threatProfileId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgSpecificControl = createAsyncThunk(
  "threatProfile/getOrgSpecificControl",
  async ({ threatProfileId, family }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `tid/threat-profile/controls_summary/${threatProfileId}?family=${family}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateControlPriority = createAsyncThunk(
  "threatProfile/updateControlPriority",
  async ({ threatProfileId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `/tid/threat-profile/baseline/priority/${threatProfileId}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteThreatProfile = createAsyncThunk(
  "threatProfile/deleteThreatProfile",
  async ({ threatProfileId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `tid/threat-profile/${threatProfileId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const makeThreatProfileDefault = createAsyncThunk(
  "threatProfile/makeThreatProfileDefault",
  async ({ threatProfileId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/threat-profile/default/${threatProfileId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "threatProfile/addProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(`tid/product`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.message[0]);
    }
  }
);

export const uploadImageToProduct = createAsyncThunk(
  "threatProfile/uploadImageToProduct",
  async ({ type, fileName, file }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `image/image-upload/static-product-icons/${fileName}`,
        file,
        {
          headers: {
            "Content-Type": `${type}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error, "error form services");
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.message);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "threatProfile/getAllProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`tid/products`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "threatProfile/deleteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(`tid/product/${id}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "threatProfile/updateProduct",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(`tid/product/${id}`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addProductToControl = createAsyncThunk(
  "threatProfile/addProductToControl",
  async ({ threatProfileId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `tid/threat-profile/baseline/product-info/${threatProfileId}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
