import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getAllThreatScenarios = createAsyncThunk(
  "threatScenario/getAllThreatScenarios",
  async ({ page = 0 }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `threat-scenario/all?page=${page}&size=10`
      );
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getScenarioEntityByID = createAsyncThunk(
  "threatScenario/getScenarioEntityByID",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`threat-scenario/${id}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
