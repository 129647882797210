import React from "react";

const ComingSoon = () => {
    return (
        <div className="comming-soon-container ">
            <span className="text1">Coming Soon</span>
            {/* <span class="text2">Get ready</span> */}
        </div>
    );
};

export default ComingSoon;
