import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";
import {
  removeEmptyKeys,
  removeEmptyKeysWithArray,
} from "../../helper/removeEmptyKeysHelper";

export const getEntityTypes = createAsyncThunk(
  "dataCreation/getEntityTypesData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/entity/types`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const generateAIEntity = createAsyncThunk(
  "dataCreation/generateAIEntity",
  async (reqestObject, { rejectWithValue }) => {
    try {
      const startTime = Date.now();
      const response = await axiosWrapper.post(`/ai`, reqestObject);
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      const customeResponse = await removeEmptyKeys(response);
      return { ...customeResponse, responseTime };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const generateAiThreatScenario = createAsyncThunk(
  "dataCreation/generateAiThreatScenario",
  async (reqestObject, { rejectWithValue }) => {
    try {
      const startTime = Date.now();
      const response = await axiosWrapper.post(
        `/ai/threat-scenario/ttp`,
        reqestObject
      );
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      const customResponse = await removeEmptyKeys(response);
      return { ...customResponse, responseTime };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getEntitiesByName = createAsyncThunk(
  "dataCreation/getEntitiesByName",
  async ({ name, filter, includeTechnique = false }, { rejectWithValue }) => {
    try {
      let endpoints = `/tid/entity`;
      if (name) {
        endpoints = endpoints + `?name=${name}&summary=true`;
      }
      if (includeTechnique) {
        endpoints = endpoints + `&include_technique=${includeTechnique}`;
      }
      if (filter && Object.keys(filter).length > 0) {
        endpoints = endpoints + `?type=${filter?.type}&name=${filter?.name}`;
      }
      const response = await axiosWrapper.get(endpoints);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createEntity = createAsyncThunk(
  "dataCreation/createEntity",
  async ({ reqestObject, isThreatScenario }, { rejectWithValue }) => {
    try {
      const data = await removeEmptyKeys(reqestObject);
      const cleanReqestObject = await removeEmptyKeysWithArray(data);
      const response = await axiosWrapper.post(
        isThreatScenario ? `threat-scenario/save` : `tid/entity/private`,
        cleanReqestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.error);
    }
  }
);

export const updateEntity = createAsyncThunk(
  "dataCreation/updateEntity",
  async ({ reqestObject, id, isThreatScenario }, { rejectWithValue }) => {
    try {
      const data = await removeEmptyKeys(reqestObject);
      const response = await axiosWrapper.put(
        isThreatScenario ? `threat-scenario/${id}` : `tid/entity/${id}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.error);
    }
  }
);
