import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getIndicators = createAsyncThunk(
  "indicators/getIndicators",
  async (
    {
      status,
      confidence,
      fromDate,
      selectedTags,
      selectedTTps,
      searchValue,
      toDate,
      type,
      page = 0,
    },
    { rejectWithValue }
  ) => {
    try {
      // debugger;
      let endPoint = `/tid/indicator?page=${page}&size=10`;
      if (type) {
        endPoint = endPoint + `&type=${type}`;
      }
      if (confidence) {
        endPoint = endPoint + `&confidence=${confidence}`;
      }
      if (searchValue) {
        endPoint = endPoint + `&text_filter=${searchValue}`;
      }
      if (status) {
        endPoint = endPoint + `&status=${status}`;
      }
      if (fromDate) {
        endPoint = endPoint + `&firstSeen=${fromDate}`;
      }
      if (toDate) {
        endPoint = endPoint + `&lastSeen=${toDate}`;
      }
      if (selectedTags && selectedTags.length > 0) {
        endPoint = endPoint + `&tags=${selectedTags}`;
      }
      if (selectedTTps && selectedTTps.length > 0) {
        endPoint = endPoint + `&ttps=${selectedTTps}`;
      }
      const response = await axiosWrapper.get(`${endPoint}`);
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getIndicatorCount = createAsyncThunk(
  "indicators/getIndicatorCountByType",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/indicator/count`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTypeOfIndicators = createAsyncThunk(
  "indicators/getTypeOfIndicators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/indicator/types`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTechniquesSummary = createAsyncThunk(
  "indicators/getTechniquesSummary",
  async ({ value }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/technique-summary?value=${value}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createIndicator = createAsyncThunk(
  "indicators/createIndicator",
  async ({ requestObject }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(`/tid/indicator`, requestObject);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createBulkIndicator = createAsyncThunk(
  "indicators/createBulkIndicator",
  async ({ requestObject, isPreview = false }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `/tid/indicator/bulk?isPreview=${isPreview}`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateIndicator = createAsyncThunk(
  "indicators/updateIndicator",
  async ({ indicatorId, requestObject }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `/tid/indicator/${indicatorId}`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteIndicator = createAsyncThunk(
  "indicators/deleteIndicator",
  async ({ indicatorId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `/tid/indicator/${indicatorId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getIndicatorById = createAsyncThunk(
  "indicators/getIndicatorById",
  async ({ indicatorId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/indicator/${indicatorId}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
