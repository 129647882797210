import { createSlice } from "@reduxjs/toolkit";
import { getSettings, saveSettings } from "../../../Services/Settings/settings.service";

const initialState = {
    loading: {
        getSettings: false,
        saveSettings: false,
    },
    settings: {},
    settingsError: null,
};

const settingsSlice = createSlice({
    initialState: initialState,
    name: "settings",
    extraReducers: (builder) => {
        builder
            .addCase(getSettings.pending, (state) => ({
                ...state,
                loading: {
                    ...state.loading,
                    getSettings: true,
                },
                settingsError: null,
            }))
            .addCase(getSettings.fulfilled, (state, action) => ({
                ...state,
                loading: {
                    ...state.loading,
                    getSettings: false,
                },
                settings: action.payload,
                settingsError: null,
            }))
            .addCase(getSettings.rejected, (state, action) => ({
                ...state,
                loading: {
                    ...state.loading,
                    getSettings: false,
                },
                settings: {},
                settingsError: action.payload,
            }))
            .addCase(saveSettings.pending, (state) => ({
                ...state,
                loading: {
                    ...state.loading,
                    saveSettings: true,
                },
                settingsError: null,
            }))
            .addCase(saveSettings.fulfilled, (state, action) => ({
                ...state,
                loading: {
                    ...state.loading,
                    saveSettings: false,
                },
                settings: action.payload,
                settingsError: null,
            }))
            .addCase(saveSettings.rejected, (state, action) => ({
                ...state,
                loading: {
                    ...state.loading,
                    saveSettings: false,
                },
                settings: {},
                settingsError: action.payload,
            }));
    },
});

export default settingsSlice.reducer;

export const getSettingsData = (state) => state.settings.settings;
export const getSettingsLoading = (state) => state.settings.loading.getSettings;
export const saveSettingsLoading = (state) => state.settings.loading.saveSettings;
