import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Badge, Box, Button, Drawer, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePolling } from "../../Hooks/usePolling";
import {
  deleteReadNotification,
  getUserNotificationsList,
  getUserNotificationsUnReadCount,
  markAllNotificationAsRead,
} from "../../redux/Slice/Notifications/notificationsSlice";
import { getUserNotifications } from "../../Services/Notifications/notifications.service";
import "./index.css";
import NotificationModal from "./NotificationModal";

const NotificationsPopUp = () => {
  const dispatch = useDispatch();

  const notificationsList = useSelector(getUserNotificationsList);
  const userNotificationsUnReadCount = useSelector(
    getUserNotificationsUnReadCount
  );

  const [open, setOpen] = useState(false);

  const getNotifications = useCallback(async () => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  const handleDeleteReadNotification = useCallback(async () => {
    dispatch(deleteReadNotification());
  }, [dispatch]);

  const toggleDrawer = () => {
    if (!open) {
      dispatch(getUserNotifications());
    }
    setOpen((pre) => !pre);
  };

  usePolling(getNotifications, 300000);
  usePolling(handleDeleteReadNotification, 1800000);

  return (
    <>
      <Badge badgeContent={userNotificationsUnReadCount || 0} color="error">
        <IconButton
          sx={{
            border: "1px solid #0082F9",
            background: "#0082F91F",
            padding: "0.6rem",
            borderRadius: "0.375rem",
          }}
          onClick={toggleDrawer}
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.79447 17.0003C7.38206 17.519 8.15398 17.8337 8.99931 17.8337C9.84473 17.8337 10.6166 17.519 11.2041 17.0003M13.9993 6.16699C13.9993 4.84091 13.4726 3.56914 12.5349 2.63146C11.5971 1.69378 10.3254 1.16699 8.99931 1.16699C7.67323 1.16699 6.40148 1.69378 5.46379 2.63146C4.52611 3.56914 3.99933 4.84091 3.99933 6.16699C3.99933 8.74216 3.34972 10.5053 2.62404 11.6715C2.01194 12.6552 1.70588 13.1471 1.71709 13.2843C1.72953 13.4362 1.76171 13.4942 1.88414 13.585C1.99471 13.667 2.49315 13.667 3.49004 13.667H14.5086C15.5055 13.667 16.004 13.667 16.1145 13.585C16.237 13.4942 16.2691 13.4362 16.2816 13.2843C16.2928 13.1471 15.9867 12.6552 15.3746 11.6715C14.6489 10.5053 13.9993 8.74216 13.9993 6.16699Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      </Badge>
      <Drawer
        style={{ margin: "2rem" }}
        id="tid-filter-drawer"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: `#08172F !important`,
            color: `#FFF !important`,
            margin: "1rem",
            padding: "1rem",
            border: "0.063rem solid #1E2B40",
            borderRadius: "0.375rem",
            height: "80%",
            width: "20%",
          },
        }}
        className="filter-drawer"
        open={open}
        anchor="right"
        onClose={toggleDrawer}
      >
        <Box className="flex-between-center notification-header">
          <Box className="flex-center-center">
            <span>Notification</span>
            <span className="notification-count-box">
              {Array.isArray(notificationsList) && notificationsList.length}
            </span>
          </Box>
          <IconButton onClick={toggleDrawer}>
            <CloseOutlinedIcon sx={{ fill: "#fff" }} />
          </IconButton>
        </Box>
        <Box className="notification-list-Box">
          {Array.isArray(notificationsList) &&
            notificationsList.length > 0 &&
            notificationsList.map((notification, notificationIndex) => {
              const notificationContent =
                (notification.content && JSON.parse(notification.content)) ||
                {};
              return (
                <NotificationModal
                  key={notificationIndex}
                  data={notificationContent}
                  isRead={notification.read}
                  notificationIndex={notificationIndex}
                  toggleDrawer={toggleDrawer}
                />
              );
            })}
        </Box>
        <Box className="notification-footer flex-between-center">
          <Button
            variant="text"
            onClick={() => {
              if (userNotificationsUnReadCount > 0) {
                dispatch(markAllNotificationAsRead());
              }
            }}
          >
            Mark all as read
          </Button>
          <Button variant="contained" onClick={toggleDrawer}>
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default NotificationsPopUp;
