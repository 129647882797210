import { createSlice } from "@reduxjs/toolkit";
import {
  getThreatProfileMatrix,
  getThreatProfileControls,
  createThreatProfile,
  getOrgSpecificControl,
  updateThreatProfile,
  getExistThreatProfile,
  getThreatProfileById,
  createThreatProfileTechBased,
  getAllProducts,
  uploadImageToProduct,
  addProductToControl,
  getAllThreatProfiles,
  makeThreatProfileDefault,
  deleteThreatProfile,
} from "../../../Services/ThreatProfile/threatProfile.service";
import {
  addControl,
  removeControl,
} from "../../../Services/TID/riskMatrix.service";

const initialState = {
  loading: {
    getThreatProfileMatrixLoading: false,
    makeThreatProfileDefaultLoading: false,
    deleteThreatProfileLoading: false,
    getThreatProfileByIdLoading: false,
    getAllThreatProfilesLoading: false,
    createThreatProfileLoading: false,
    getThreatProfileControlsLoading: false,
    removeControlLoading: false,
    updateThreatProfileLoading: false,
    addProductToControlLoading: false,
    getOrgSpecificControlLoading: false,
  },
  threatProfileMatrixData: [],
  threatProfileMatrixError: null,
  threatControlsData: [],
  threatControlsError: null,
  allThreatProfiles: [],
  threatProfileData: {},
  threatProfileDataError: null,
  threatExists: null,
  tabValue: 0,
  familyType: "AC",
  productsList: [],
  img: {},
};

const threatProfileSlice = createSlice({
  name: "threatProfile",
  initialState: initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setFamilyType: (state, action) => {
      state.familyType = action.payload;
    },
    resetThreatControlsData: (state, action) => {
      state.threatControlsData = [];
    },
    resetThreatProfileData: (state, action) => {
      state.threatProfileData = {};
    },
    resetThreatProfileMatrixData: (state, action) => {
      state.threatProfileMatrixData = [];
    },
    resetThreatProfileExist: (state, action) => {
      state.threatExists = false;
    },
    resetTabValue: (state, action) => {
      state.tabValue = 0;
    },
    resetfamilyType: (state, action) => {
      state.familyType = "AC";
    },
    setThreatProfileData: (state, action) => {
      state.threatProfileData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreatProfileMatrix.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileMatrixLoading: true,
        },
        threatProfileMatrixError: null,
      }))
      .addCase(getThreatProfileMatrix.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileMatrixLoading: false,
        },
        threatProfileMatrixData: action.payload,
        threatProfileMatrixError: null,
      }))
      .addCase(getThreatProfileMatrix.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileMatrixLoading: false,
        },
        threatProfileMatrixData: [],
        threatProfileMatrixError: action.payload,
      }))

      .addCase(getThreatProfileControls.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileControlsLoading: true,
        },
        threatControlsError: null,
      }))
      .addCase(getThreatProfileControls.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileControlsLoading: false,
        },
        threatControlsData: action.payload,
        threatControlsError: null,
      }))
      .addCase(getThreatProfileControls.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileControlsLoading: false,
        },
        threatControlsData: [],
        threatControlsError: action.payload,
      }))
      .addCase(createThreatProfile.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          createThreatProfileLoading: true,
        },
        threatProfileDataError: null,
      }))
      .addCase(createThreatProfile.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createThreatProfileLoading: false,
        },
        threatProfileData: action.payload,
        threatProfileDataError: null,
      }))
      .addCase(createThreatProfile.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createThreatProfileLoading: false,
        },
        threatProfileData: [],
        threatProfileDataError: action.payload,
      }))
      .addCase(createThreatProfileTechBased.pending, (state) => ({
        ...state,
        loading: true,
        threatProfileDataError: null,
      }))
      .addCase(createThreatProfileTechBased.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        threatProfileData: action.payload,
        threatProfileDataError: null,
      }))
      .addCase(createThreatProfileTechBased.rejected, (state, action) => ({
        ...state,
        loading: false,
        threatProfileData: {},
        threatProfileDataError: action.payload,
      }))
      .addCase(getAllThreatProfiles.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getAllThreatProfilesLoading: true,
        },
        threatProfileDataError: null,
      }))
      .addCase(getAllThreatProfiles.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getAllThreatProfilesLoading: false,
        },
        allThreatProfiles: action.payload,
        threatProfileDataError: null,
      }))
      .addCase(getAllThreatProfiles.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getAllThreatProfilesLoading: false,
        },
        allThreatProfiles: [],
        threatProfileDataError: action.payload,
      }))
      .addCase(getThreatProfileById.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileByIdLoading: true,
        },
        threatProfileDataError: null,
      }))
      .addCase(getThreatProfileById.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileByIdLoading: false,
        },
        threatProfileData: action.payload,
        threatProfileDataError: null,
      }))
      .addCase(getThreatProfileById.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getThreatProfileByIdLoading: false,
        },
        threatProfileData: [],
        threatProfileDataError: action.payload,
      }))
      .addCase(getOrgSpecificControl.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getOrgSpecificControlLoading: true,
        },
      }))
      .addCase(getOrgSpecificControl.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getOrgSpecificControlLoading: false,
        },
      }))
      .addCase(getOrgSpecificControl.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getOrgSpecificControlLoading: false,
        },
      }))
      .addCase(updateThreatProfile.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          updateThreatProfileLoading: true,
        },
      }))
      .addCase(updateThreatProfile.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          updateThreatProfileLoading: false,
        },
      }))
      .addCase(updateThreatProfile.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          updateThreatProfileLoading: false,
        },
      }))
      .addCase(deleteThreatProfile.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteThreatProfileLoading: true,
        },
      }))
      .addCase(deleteThreatProfile.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteThreatProfileLoading: false,
        },
      }))
      .addCase(deleteThreatProfile.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteThreatProfileLoading: false,
        },
      }))
      .addCase(getExistThreatProfile.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getExistThreatProfile.fulfilled, (state, action) => ({
        ...state,
        threatExists: action.payload,
        loading: false,
      }))
      .addCase(getExistThreatProfile.rejected, (state, action) => ({
        ...state,
        threatExists: null,
        loading: false,
      }))
      .addCase(getAllProducts.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getAllProducts.fulfilled, (state, action) => ({
        ...state,
        productsList: action.payload,
        loading: false,
      }))
      .addCase(getAllProducts.rejected, (state, action) => ({
        ...state,
        productsList: null,
        loading: false,
      }))
      .addCase(addProductToControl.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          addProductToControlLoading: true,
        },
      }))
      .addCase(addProductToControl.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          addProductToControlLoading: false,
        },
      }))
      .addCase(addProductToControl.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          addProductToControlLoading: false,
        },
      }))
      .addCase(uploadImageToProduct.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(uploadImageToProduct.fulfilled, (state, action) => ({
        ...state,
        img: action.payload,
        loading: false,
      }))
      .addCase(uploadImageToProduct.rejected, (state, action) => ({
        ...state,
        img: null,
        loading: false,
      }))
      .addCase(makeThreatProfileDefault.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          makeThreatProfileDefaultLoading: true,
        },
      }))
      .addCase(makeThreatProfileDefault.fulfilled, (state, action) => ({
        ...state,
        img: action.payload,
        loading: {
          ...state.loading,
          makeThreatProfileDefaultLoading: false,
        },
      }))
      .addCase(makeThreatProfileDefault.rejected, (state, action) => ({
        ...state,
        img: null,
        loading: {
          ...state.loading,
          makeThreatProfileDefaultLoading: false,
        },
      }))
      .addCase(addControl.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          addControlLoading: true,
        },
      }))
      .addCase(addControl.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          addControlLoading: false,
        },
      }))
      .addCase(addControl.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          addControlLoading: false,
        },
      }))
      .addCase(removeControl.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          removeControlLoading: true,
        },
      }))
      .addCase(removeControl.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          removeControlLoading: false,
        },
      }))
      .addCase(removeControl.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          removeControlLoading: false,
        },
      }));
  },
});

export const {
  setTabValue,
  setFamilyType,
  resetThreatControlsData,
  resetThreatProfileMatrixData,
  resetThreatProfileData,
  resetThreatProfileExist,
  resetTabValue,
  resetfamilyType,
  setThreatProfileData,
} = threatProfileSlice.actions;
export default threatProfileSlice.reducer;

export const getActiveTabValue = (state) => state.threatProfile.tabValue;
export const getfamilyType = (state) => state.threatProfile.familyType;
export const getThreatPrfileExists = (state) =>
  state.threatProfile.threatExists;
export const getThreatProfileMatrixData = (state) =>
  state.threatProfile.threatProfileMatrixData;
export const getThreatProfileData = (state) =>
  state.threatProfile.threatProfileData;
export const getThreatControlsData = (state) =>
  state.threatProfile.threatControlsData;
export const getProductList = (state) => state.threatProfile.productsList;
export const getAllThreatProfilesData = (state) =>
  state.threatProfile.allThreatProfiles;
export const getThreatProfileLoading = (state) => state.threatProfile.loading;
