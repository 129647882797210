import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CloseIcon from "@mui/icons-material/Close";
import CustomAutocomplete from "../Components/Custom/CustomAutocomplete";
import CustomTextField from "../Components/Custom/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTooltip from "../Components/Custom/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import useToastify from "../Hooks/useToastify";
import { submitFeedback } from "../Services/Auth/Auth.service";
import { getfeedbackLoading } from "../redux/Slice/Auth/authSlice";
import CustomLoadingButton from "../Components/Custom/CustomLoadingButton";

const feedbackAboutOptions = [
  { title: "General Feedback" },
  { title: "Missing Functionality" },
  { title: "Technical Issues" },
  { title: "New Features Request" },
  { title: "Other" },
];

const feedbackFunctionalityOptions = [
  { title: "Entire Platform" },
  { title: "List of Threat Entities" },
  { title: "ATT&CK Matrix" },
  { title: "Remediation" },
  { title: "DE&TH" },
  { title: "Recommendation" },
  { title: "Risk Matrix" },
  { title: "Threat Profile" },
  { title: "Other" },
];

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const { showToast } = useToastify();

  const feedbackLoading = useSelector(getfeedbackLoading);

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      type: feedbackAboutOptions[0].title, // Default to first option
      functionality: feedbackFunctionalityOptions[0].title, // Default to first option
      description: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please choose a type of feedback"),
      functionality: Yup.string().required(
        "Please choose an appropriate functionality"
      ),
      description: Yup.string()
        .required("Please provide as much detail as possible")
        .min(10, "Details should be at least 10 characters"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await dispatch(
          submitFeedback({ requestObject: values })
        ).unwrap();
        if (response && response === "OK") {
          showToast("Feedback successfully submitted", { type: "success" });
          setOpen(false);
        }
      } catch (error) {}
    },
  });

  const handleOpenClose = () => {
    setOpen(!open);
    formik.resetForm();
  };

  return (
    <>
      <div className="feedback-button">
        <CustomTooltip title="Please give your feedback.">
          <Button
            sx={{
              backgroundColor: "transparent",
              borderRadius: "100%",
              padding: "1rem",
              height: "4rem",
              width: "4rem",
            }}
            color="primary"
            variant="outlined"
            onClick={handleOpenClose}
          >
            <FeedbackIcon sx={{ width: "2rem" }} />
          </Button>
        </CustomTooltip>
      </div>
      <Dialog
        open={open}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        onClose={handleOpenClose}
        fullWidth
        id="rule-list-container"
        sx={{
          "& .MuiPaper-root": {
            background: "#112038 !important",
            color: "#fff !important",
            padding: "0.5rem 0.5rem !important",
          },
          "& .MuiTypography-root": {
            color: "#fff !important",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignrows: "center",
            padding: "0.5rem 1.5rem",
            color: "white",
            fontWeight: "400",
          }}
        >
          <span style={{ fontSize: "1.5rem" }}>Feedback</span>
          <div style={{ cursor: "pointer" }} onClick={handleOpenClose}>
            <CloseIcon sx={{ color: "rgba(142, 151, 164, 1)" }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider
            sx={{
              background: "rgba(255, 255, 255, 0.08)",
              borderWidth: "1px",
            }}
          />
          <form
            onSubmit={formik.handleSubmit}
            className="feedback-form-container"
          >
            <div className="feedback-form-field">
              <label className="feedback-form-label">
                Kindly choose the type of Feedback
              </label>
              <CustomAutocomplete
                fullWidth
                options={feedbackAboutOptions}
                getOptionLabel={(option) => option.title}
                value={feedbackAboutOptions.find(
                  (option) => option.title === formik.values.type
                )}
                onChange={(event, value) =>
                  formik.setFieldValue("type", value ? value.title : "")
                }
                onBlur={formik.handleBlur}
                Sx={{
                  border: `1px solid ${
                    formik.touched.type && formik.errors.type
                      ? "#d32f2f"
                      : "#1E2B40"
                  }`,
                }}
              />
              <FormHelperText error>
                {formik.touched.type && formik.errors.type}
              </FormHelperText>
            </div>
            <div className="feedback-form-field">
              <label className="feedback-form-label">
                Kindly choose the appropriate functionality, if any, for the
                feedback
              </label>
              <CustomAutocomplete
                fullWidth
                options={feedbackFunctionalityOptions}
                getOptionLabel={(option) => option.title}
                value={feedbackFunctionalityOptions.find(
                  (option) => option.title === formik.values.functionality
                )}
                onChange={(event, value) =>
                  formik.setFieldValue(
                    "functionality",
                    value ? value.title : ""
                  )
                }
                onBlur={formik.handleBlur}
                Sx={{
                  border: `1px solid ${
                    formik.touched.functionality && formik.errors.functionality
                      ? "#d32f2f"
                      : "#1E2B40"
                  }`,
                }}
              />
              <FormHelperText error>
                {formik.touched.functionality && formik.errors.functionality}
              </FormHelperText>
            </div>
            <div className="feedback-form-field">
              <label className="feedback-form-label">
                Provide as much detail as possible.
              </label>
              <CustomTextField
                fullWidth
                multiline
                maxRows={4}
                rows={4}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                styleSx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid rgba(255, 255, 255, 0.08) !important",
                    borderColor: `${
                      formik.touched.description && formik.errors.description
                        ? "#d32f2f"
                        : "rgba(255, 255, 255, 0.08)"
                    } !important`,
                  },
                }}
              />
              <FormHelperText error>
                {formik.touched.description && formik.errors.description}
              </FormHelperText>
            </div>
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button variant="outlined" color="error" onClick={handleOpenClose}>
              Close
            </Button>
            <CustomLoadingButton
              loading={feedbackLoading}
              variant="outlined"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Submit
            </CustomLoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeedbackForm;
