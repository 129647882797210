import { createSlice } from "@reduxjs/toolkit";
import { getSearchResults } from "../../../Services/TID/workspace.service";

const initialState = {
    loading: false,
    searchLoading: false,
    searchResultData: {},
    searchError: null,
    searchValue: "",
    startDate: "",
    endDate: "",
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState: initialState,
    reducers: {
        setSearchValue: (state, action) => ({
            ...state,
            searchValue: action.payload,
        }),
        setStartDate: (state, action) => ({
            ...state,
            startDate: action.payload,
        }),
        setEndDate: (state, action) => ({
            ...state,
            endDate: action.payload,
        }),
        resetSeachValues: (state) => ({
            ...state,
            ...initialState,
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSearchResults.pending, (state) => ({
                ...state,
                searchLoading: true,
                searchError: null,
            }))
            .addCase(getSearchResults.fulfilled, (state, action) => ({
                ...state,
                searchLoading: false,
                searchResultData: action.payload,
                searchError: null,
            }))
            .addCase(getSearchResults.rejected, (state, action) => ({
                ...state,
                searchLoading: false,
                searchResultData: {},
                searchError: action.payload,
            }));
    },
});

export default workspaceSlice.reducer;
export const { setSearchValue, setStartDate, setEndDate, resetSeachValues } = workspaceSlice.actions;

export const getSearchLoading = (state) => state.workspace.searchLoading;
export const getSearchResultData = (state) => state.workspace.searchResultData;
export const getSearchValue = (state) => state.workspace.searchValue;
export const getStartDate = (state) => state.workspace.startDate;
export const getEndDate = (state) => state.workspace.endDate;
