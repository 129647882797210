import { createSlice } from "@reduxjs/toolkit";
import { getUserNotifications } from "../../../Services/Notifications/notifications.service";

const initialState = {
  loading: {
    getUserNotifications: false,
  },
  error: {
    getUserNotifications: null,
  },
  notifications: [],
};

const notificationsSlice = createSlice({
  initialState,
  name: "notifications",
  reducers: {
    markNotificationAsRead: (state, action) => {
      const { notificationIndex } = action.payload;
      state.notifications[notificationIndex] = {
        ...state.notifications[notificationIndex],
        read: true,
      };
    },
    markAllNotificationAsRead: (state) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: true,
      }));
    },
    deleteReadNotification: (state) => {
      state.notifications = state.notifications.filter(
        (notification) => !notification.read
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserNotifications.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getUserNotifications: true,
        },
        error: {
          ...state.error,
          getUserNotifications: null,
        },
      }))
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        let newNotifications = action.payload.map((notification) => ({
          ...notification,
          read: false,
        }));

        const totalNotifications =
          state.notifications.length + newNotifications.length;

        if (totalNotifications > 30) {
          const excessCount = totalNotifications - 30;
          state.notifications = state.notifications.slice(excessCount);
        }
        state.notifications = [...newNotifications, ...state.notifications];
        state.loading.getUserNotifications = false;
        state.error.getUserNotifications = null;
      })
      .addCase(getUserNotifications.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getUserNotifications: false,
        },
        // notifications: initialState.notifications,
        error: {
          ...state.error,
          getUserNotifications: action.payload,
        },
      }));
  },
});

export const getUserNotificationsList = (state) =>
  state.notifications.notifications;
export const getUserNotificationsUnReadCount = (state) =>
  state.notifications.notifications.filter((notification) => !notification.read)
    .length;

export const {
  markNotificationAsRead,
  markAllNotificationAsRead,
  deleteReadNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
