import { createSlice } from "@reduxjs/toolkit";
import {
  getAllThreatScenarios,
  getScenarioEntityByID,
} from "../../../Services/TID/threatScenario.service";

const initialState = {
  threatScenarioLoading: false,
  threarScenarioList: [],
  scenarioEntityID: {},
  threatScenarioError: null,
  pagination: {
    scenarioCurrentPage: 0,
    scenarioPageSize: 10,
    scenarioTotalElements: 0,
    scenarioTotalPages: 0,
    scenarioLastPage: false,
  },
};
const threatScenarioSlice = createSlice({
  name: "threatScenario",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllThreatScenarios.pending, (state) => ({
        ...state,
        threatScenarioLoading: true,
        threatScenarioError: null,
      }))
      .addCase(getAllThreatScenarios.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          threatScenarioLoading: false,
          threarScenarioList: loadmore
            ? [...state.threarScenarioList, ...response.content]
            : response.content,
          threatScenarioError: null,
          pagination: {
            ...state.pagination,
            scenarioCurrentPage: response?.number || page,
            scenarioTotalElements: response?.totalElements,
            scenarioTotalPages: response?.totalPages,
            scenarioLastPage: response?.last,
          },
        };
      })
      .addCase(getAllThreatScenarios.rejected, (state, action) => ({
        ...state,
        threatScenarioLoading: false,
        threarScenarioList: [],
        threatScenarioError: action.payload,
      }))
      .addCase(getScenarioEntityByID.pending, (state) => ({
        ...state,
        threatScenarioLoading: true,
      }))
      .addCase(getScenarioEntityByID.fulfilled, (state, action) => ({
        ...state,
        threatScenarioLoading: false,
        scenarioEntityID: action.payload,
      }))
      .addCase(getScenarioEntityByID.rejected, (state) => ({
        ...state,
        threatScenarioLoading: false,
        scenarioEntityID: {},
      }));
  },
});

export default threatScenarioSlice.reducer;

export const getThreatScenarioListData = (state) =>
  state.threatScenario.threarScenarioList;
export const getThreatScenarioLoading = (state) =>
  state.threatScenario.threatScenarioLoading;
